html,
body {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  height: 100%;
  width: 100%;
}

// font family: Cormorant
@import url("https://fonts.googleapis.com/css2?family=Cormorant:ital,wght@0,300..700;1,300..700&display=swap");
// font-family: "Cormorant", serif;

// font family: Montserrat
@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap");
// font-family: "Montserrat", sans-serif;

// font family: Manrope
@import url("https://fonts.googleapis.com/css2?family=Manrope:wght@200..800&display=swap");
// font-family: "Manrope", sans-serif;

